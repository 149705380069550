<template>
  <v-container class="c-orderDetails">
    <v-col
      cols="12"
      v-if="loading"
      class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </v-col>
    <template v-else>
      <v-card
        class="my-2 grey lighten-2"
        v-if="isSuperAdmin">
        <CompanyInformation />
      </v-card>
      <GeneralInformation  class="mb-2" />
      <v-layout>
        <v-flex grow>
          <OrderStatus />
        </v-flex>
        <v-flex
          shrink
          class="ml-4">
          <DuplicationButton
            v-if="isSuperAdmin && order.storno" />
        </v-flex>
        <v-flex
          shrink
          class="ml-4">
          <Cancellation />
        </v-flex>
      </v-layout>
      <v-row v-if="order.storno !== null">
        <v-col cols="12">
          <v-alert
            :value="true"
            type="error"
            class="mb-0"
          >
            Dieser Auftrag wurde am {{ order.storno | date}} storniert.
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          lg="8"
        >
          <v-card>
            <v-card-text>
              <ProductsList/>
              <v-divider
                class="c-contentCard__divider -fullWidth -height-2"
              ></v-divider>
              <v-divider
                class="c-contentCard__divider -fullWidth -height-2"
              ></v-divider>
              <OrderTotalPrice/>
              <v-divider
                class="c-contentCard__divider -fullWidth -height-2"
              ></v-divider>
              <CustomerNotes />
            </v-card-text>
          </v-card>
          <v-card class="mt-4">
            <v-card-text>
              <Notes/>
            </v-card-text>
          </v-card>
          <v-card class="mt-4">
            <v-card-text>
              <Logs/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          col="6"
          lg="4"
        >
          <v-card class="mb-4">
            <v-card-text>
              <AppointmentTime v-if="showAppointmentTime" />
              <v-divider
                v-if="showAppointmentTime"
                class="c-contentCard__divider -fullWidth mb-4" />
              <BranchOfficeAddress v-if="showBranchOfficeAddress" />
              <CustomerAddress
                v-else
                type="delivery" />
              <v-divider class="c-contentCard__divider -fullWidth mb-4" />
              <CustomerAddress type="billing" />
            </v-card-text>
          </v-card>
          <v-card class="mb-4">
            <v-card-text>
              <Payment/>
            </v-card-text>
          </v-card>
          <v-card class="mb-4">
            <v-card-text>
              <Documents />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GeneralInformation from '@/components/orderDetails/GeneralInformation.vue'
import CustomerAddress from '@/components/orderDetails/CustomerAddress.vue'
import AppointmentTime from '@/components/orderDetails/AppointmentTime.vue'
import OrderStatus from '@/components/orderDetails/OrderStatus.vue'
import ProductsList from '@/components/orderDetails/ProductsList.vue'
import OrderTotalPrice from '@/components/orderDetails/OrderTotalPrice.vue'
import Payment from '@/components/orderDetails/Payment.vue'
import CustomerNotes from '@/components/orderDetails/CustomerNotes.vue'
import Documents from '@/components/orderDetails/Documents.vue'
import CompanyInformation from '@/components/orderDetails/CompanyInformation.vue'
import Logs from '@/components/orderDetails/Logs.vue'
import Notes from '@/components/orderDetails/Notes.vue'
import Cancellation from '@/components/orderDetails/Cancellation.vue'
import DuplicationButton from '@/components/orderDetails/DuplicationButton.vue'
import BranchOfficeAddress from '../../components/orderDetails/BranchOfficeAddress.vue'

export default {
  created() {
    this.setLoading(true)
    this.fetchOrder(this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: 'auth/isSuperAdmin',
      loading: 'order/loading',
      order: 'order/order',
    }),
    showBranchOfficeAddress() {
      if (this.order.delivery === 'pickup') {
        return true
      }
      return false
    },
    showAppointmentTime() {
      return (
        this.order.delivery === 'own'
        && (
          this.order.subdelivery === 'own_own'
          || this.order.subdelivery === 'own_pickup'
        )
      )
    },
  },
  methods: {
    ...mapActions('order', ['fetchOrder', 'setLoading']),
  },
  components: {
    GeneralInformation,
    CustomerAddress,
    AppointmentTime,
    OrderStatus,
    ProductsList,
    OrderTotalPrice,
    Payment,
    CustomerNotes,
    Documents,
    CompanyInformation,
    Logs,
    Notes,
    Cancellation,
    BranchOfficeAddress,
    DuplicationButton,
  },
}
</script>

<style lang="scss">
.c-orderDetails {
  h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px !important;
      line-height: 19px !important;
  }
  .theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle{
      color:#000000;
  }
}
</style>
